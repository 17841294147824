/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
table {
  width: 100%;
  display: table;
}

.buttons {
  float: right;
}
.buttons > * {
  margin-right: 16px;
}
.mat-row:hover {
  background-color: #f8f8f8;
}

.mat-row:hover > .mat-cell {
  color: #2a52be;
}

.mat-header-cell {
  background-color: #2a52be;
  color: white;
}

.over-Flow{
  overflow: auto;
}

.title {
  font-weight: 600;
  color: #2a52be;
}

.backButton {
  background-color: #2a52be;
  color: white;
}

.addButton {
  background-color: #4cbb17;
  color: white;
}

label {
  display: inline-block;
  width: 150px; 
}

.center{
  align-items: center;
}

.mat-select {
  background-color: rgb(228, 236, 228); 
 
} 
==================================================================*/

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.example-spacer {
    flex: 1 1 auto;
  }

  .table {
    width: 100%;
    display: table;
    text-align:center ;
  }
 
  .buttons {
    float: right;
  }
  .mat-row:hover {
    background-color: #c4e4f9;
  }
 
  .mat-row:hover > .mat-cell {
    color: #2a52be;
  }
 
  .mat-header-cell {
    color: #9FA2B4;
    font-size: 16px;
    line-height: 1.5em;
  }
  td.mat-cell{
    font-size: 14px;
    line-height: 1.5em;
  }
  .title {
     
    color: #2a52be;
  }
 
 
  .addButton {
    background-color: #00a9df;
    margin: 10px !important;
    border: 1px solid #00a9df !important;
    color: #fff;
    transition: all 0.3s ease-in-out; 
  }

  .addButton:hover{
    border: 1px solid #00a9df !important;
    background-color: #fff;
    color: #00a9df;
  }

  .removeButton {
    background-color: #fc2626;
    color: white;
    margin: 10px !important;
  }
 
 
  label {
    display: inline-block;
    width: 150px;
    /* text-align: center; */
  }

  .title{
    color: #fff;
    text-align: center;
  }
 
 
/* table.mat-table {
    border-spacing: 0;
    width: 100%;
    box-shadow: none;
    border: 1px solid #DFE0EB;
    position: relative;
    top: 0;
} */

table.mat-table {
  border-spacing: 0;
  width: 100%;
  box-shadow: none;
  border: 1px solid #DFE0EB;
  text-align:center ;
}

/* .mat-paginator{
  display: block;
  position: relative;
  width: 100%;
  bottom: 3vw;
  left: 0;
} */
  .center {
    align-items: center;
  }
 
  .mat-card-header{    
    padding: 5px;
    padding-bottom: 15px!important;
    /* border-bottom: 1px solid #c6c6c6; */
    padding-bottom: 1vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 
 
  /* .mat-select {
    background-color: rgb(228, 236, 228);
    padding: 10px;
   
  } */

  .buttonCss{
    background-color: aqua;
    color: black;
    border: none;
   
}

 
.mat-form-field
{
    padding-left: 20px; 
}
.login-mat-form-field
{
    width: 90% !important;
    display: block !important;
    margin: auto;
}
.remove{
    background-color: brown;
    color: white;

}
.addLocation{
    background-color: green;
    color: white;

}
.table2 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;  
  border-radius: 10px 10px 10px 10px !important ;
  }
 
  th, td {
    text-align: center;
    padding: 8px;
    border: 1px solid #dbdbdd;
    
  }
/* th:hover, td.hover{
  background-color: #004c8b;
} */
/* th:hover, td.hover, th:active, td:active{
  cursor: move!important;
  cursor: col-resize!important;
  resize: horizontal!important;
} */
th{
  /* cursor: col-resize; */
  resize: horizontal!important;
  overflow: auto;
}
tr:nth-child(even){
background-color: #f0f0f0;
}
tr:nth-child(odd){
background-color: #e8e8e8;
}

 .update{
   background-color: cornflowerblue;
   color: aliceblue;
 }

 .tally-top-btns
{
    padding-top: 20px;
    padding-left: 0 !important;
    position: relative;
    top: -5.7vw;
}

.mat-card-title{
  color: #252733;
  font-weight: 700;
}

.mat-toolbar.mat-primary {
  background: #fff;
  color: #fff;
}

.tally-search-btn{
  float: right;
  margin-right: 0.5vw !important;
  top: -1vw;
  min-width: 15vw;
  width: 12vw;
  background: #00a9df;
  color: #fff;
}

.mat-raised-button{
  font-size: 1vw;
}

.mat-button{
  font-size: 1vw;
}

.mat-sidenav
{
  padding: 12px !important;
}

.signout
{
  background: #004c8b;
  color: #fff;
  padding-right: 20px;
}
.loginSpace
{
width:30vw;
margin-top:5em;
}

.accountSelectSpace
{
width:30vw;
margin-top:1em;
}

/* .loginSpace
{
width:270px;
margin-top:5em;
} */
 
.mat-card-header .mat-card-title
{
  margin: 5px !important;
  font-size: 2vw;
  font-weight: 500;
  font-family: 'Roboto';
  color: #f2f2f3; /*padmaja*/
}
  
  

.mat-card-content{
  position: relative;
  top: 2vw !important;
}
.table{
  top: 2vw !important;
}
.mat-card-actions, .mat-card-subtitle, .mat-card-content {
  display: block;
  margin-bottom: 48px;
}
.full-width2
{
  width: 330px !important;
  font-size: 15px;
}
.full-width3
{
  width: 90% !important;
  padding-top: -10px;
  font-size: 15px;
}
.form-control-search-field{
  padding:10px;
  margin-right:5px;
  margin-top: 20px;
  margin-left:5px;
  border-radius:10px;
  display: block !important;
  width:300px;
  line-height: 1.5;
  /* border-color: #172c59 !important; */
  border-top-color: #10d97c !important;
  border-left-color: #10d97c !important;
  border-right-color: #198754 !important;
  border-bottom-color: #198754 !important;
  color:#172c59;
  background-color: #f0f0f0;
  float: right;
  }
  
 table .form-control-search-field{
padding:5px;
margin-right:5px;
margin-top: 5px;
margin-left:5px;
margin-bottom: 10px;
border-radius:10px;
width:inherit;
line-height: 1.5;
/* border-color: #8cc0db !important; */
border-top-color: #10d97c !important;
border-left-color: #10d97c !important;
border-right-color: #198754 !important;
border-bottom-color: #198754 !important;
color:#172c59;
background-color: #f0f0f0;
float:inherit;
}

.mat-form-field-infix
{
  width: 200px !important;
  /* 155 to 200px padmaja */
  font-size: 15px;
}
.mat-list-base .mat-list-item.mat-list-item-with-avatar, .mat-list-base .mat-list-option.mat-list-item-with-avatar {
  height: 35px !important;
    /* 45 to 35px padmaja */
}
.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  display: block;
  height: 35px !important;
  padding: 0px !important;
}
 .mat-list-item-content {
  padding: 0px !important;
  word-wrap: break-word !important; 
  }

  .mat-sidenav-content{
    padding-top: 4vw;
    background-color: #fff;
  }

  .mat-cell{
    font-size: 0.9vw;
  }

  .mat-card.mat-focus-indicator{
    box-shadow: none;
  }

  .tally-table{
    position: relative;
    top: 2vw !important;
    text-align:center ;
  }

  .tally-table-pagination{
    position: relative;
    top: 35px;
  }
  .tally-serach-form .form{
    bottom: 35px;
  }
  .new-tally-form{
    position: relative;
    top: 2vw;
  }

  .backButton {
    margin-right: 10px !important;
    background-color: #00a9df; 
  border-radius: 30px;
  color:#ffffff;
   
  }
  .go-back-btn{
    height: 2vw;
    position: relative;
    background-color: #00a9df !important;
    width: 2vw;
    padding: 0 !important;
  }

  .new-tally-form-btn{
    top: 2vw;
  }

  .mat-card-actions .mat-raised-button{
    float: right;
    width: 9vw;
    margin-right: 2vw !important;
  }

  .backButton{
    height: 2vw;
    position: relative; 
    min-width: 2vw !important;
    align-items: center;
    border-radius: 30px;
    background-color: #ffffff !important; 
    color: #00a9df !important;
    margin: top 5px;
  }
  .backButton:hover{
border-radius: 2px solid #0c0c0c ;
  }
  .backButton span{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .toggle-btn{
    display: none;
  } */

  tr.mat-header-row th {
    background: #00a9df;
    color: #fff;
  }

.mat-drawer-inner-container::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #555;
  
}

.mat-drawer-inner-container::-webkit-scrollbar{
	width: 12px;
	background-color: #555;
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #F5F5F5;
}

.cdk-overlay-pane .mat-menu-content{
  padding: 1.5vw;
  
}

.cdk-overlay-pane .mat-slide-toggle{
  height: 2.5vw;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #305095;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 169 223);
}

.mat-slide-toggle-label{
    color:#00a9df;
    font-weight: 500;

}


@media screen and (max-width: 767px) {
  .toggle-btn{
    display: block;
  }

  .admin-user-header{
    justify-content: space-between;
    min-height: 15vw !important;
  }

  .mat-sidenav-content{
    margin-left: 0 !important;
  }

  .mat-form-field{
    width: 100%;
    padding-right: 20px;
    font-size: 16px;
  }
  .mat-raised-button {
    font-size: 16px;
    top: -5vw;
    float: right !important;
  }

  .mat-card-actions .mat-raised-button{
    margin-right: 6vw !important;
  }

  .mat-card-header .mat-card-title{
    font-size: 24px;
    line-height: normal;
  }
  .mat-card-header{
    position: relative;
    height: 100%;
    top: 10vw;
    min-height: 1vw;
  }
  .mat-dialog-container .mat-card-header{
    top: 5vw;
  }
  .mat-button{
    font-size: 14px;
    margin: 10px 10px 10px 0 !important;
  }

  .mat-card-header {
    display: block !important;
  }

  .mat-card-header .buttons{
    /* top: 3vw; */
    position: relative;
  }

  /* .mat-header-cell, .mat-cell{
    font-size: 4vw;
  } */

  .mat-card-content{
    position: relative;
    top: 10vw;
  }

  th.mat-header-cell{
    min-width: 149px;
    padding-left: 20px !important;
    padding-right: 20px !important; 
  }
    .buttons {
      float: unset;
  }

  .tally-serach-form{
    top: 15vw !important;
  }

  .tally-search-btn{
    width: 95%;
    top: -10vw;
  
  }

  .backButton{
    top:1vw;
    height: 6vw;
    min-width: 6vw !important;
    margin-right: 10px;
  }
  
  .dropdown-menu .mat-raised-button{
    top:0 !important;
  }

  table.mat-table{
    /* top: 50px; */
    width: 92vw;
    display: block;
    overflow-x: scroll; 
  border-radius: 10px 10px 10px 10px;
  }

  .cdk-overlay-pane .mat-slide-toggle{
    height: 9vw;

  }
  
}


@media screen and (min-width: 760px) and (max-width: 812px){
  .toggle-btn{
    display: block;
  }

  .admin-user-header{
    justify-content: space-between;
    min-height: 6vw !important;
  }

  .mat-sidenav-content{
    margin-left: 0 !important;
  }

  .mat-form-field{
    width: 100%;
    padding-right: 20px;
    font-size: 16px;
  }
  .mat-raised-button {
    font-size: 16px;
    top: 0;
    float: right !important;
  }

  .mat-card-actions .mat-raised-button{
    margin-right: 6vw !important;
  }

  .mat-card-header .mat-card-title{
    font-size: 24px;
    line-height: normal;
  }
  .mat-card-header{
    position: relative;
    height: 100%;
    /* top: 10vw; */
    min-height: 10vw;
    margin-top: 5px !important;
    margin-bottom: 30px !important;
  }

  .mat-button{
    font-size: 14px;
    margin: 10px 10px 10px 0px !important;
  }

  .mat-card-header {
    display: flex !important;
    align-items: flex-start;
    top: 0;
  }

  .mat-card-header .buttons{
    /* top: 3vw; */
    position: relative;
  }

  /* .mat-header-cell, .mat-cell{
    font-size: 2vw;
  } */

  .mat-card-content{
    position: relative;
    top: 0;
  }

  th.mat-header-cell{
    min-width: 100%;
    padding-left: 20px !important;
  }
    .buttons {
      float: unset;
  }

  .tally-serach-form{
    top: 0 !important;
  }

  .tally-search-btn{
    top: 0;
  }

  .backButton{
    top:1vw;
    height: 6vw;
    min-width: 6vw !important;
    margin-right: 10px;
  }
  
  .dropdown-menu .mat-raised-button{
    top:0 !important;
  }

  table.mat-table{
    width: 100%;
    display: table;
    overflow-x: scroll;
  }

  .cdk-overlay-pane .mat-slide-toggle{
    height: 9vw;

  }
}


.main-header-title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #00a9df;
  align-items: center;
}

.main-header-title .mat-card-title{
  color: #fff;
}


.main-header-title .mat-card-header{
  margin-top: 10px;
}

.main-header-title .mat-icon{
  color: #fff;
}

.main-header-title .mat-card-header .mat-card-title{
  font-size: 17px;
}

.main-header-title .mat-card-header{
  height: 3vw;
}

.more_options_btn ul{
  overflow-y: scroll;
  max-height: 20vw;
}

.more_options_btn ul::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.more_options_btn ul::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.more_options_btn ul::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

.mat-paginator-icon{
  background-color: #00a9df; 
  border-radius: 30px;
  color:#ffffff;
}
.mat-paginator-icon:hover{
  /* background-color: #00a9df; */
  background-color: #172c59; 
  border-radius: 30px;
  color:#ffffff;
}
tr.mat-header-row th {
  text-align: center;
}
tr.mat-header-row th:first-child {
 border-radius: 10px 0 0 0;
}
tr.mat-header-row th:last-child {
  border-radius: 0 10px 0 0 ;
 }
 
  .mat-card>:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px 10px 10px 10px !important;
    background-image: -webkit-gradient(linear, right top, left bottom, 
       color-stop(0.3,  #172c59),
        color-stop(1, #069ed1));   
   /* background-color: #C2D6D6 ;  */
     -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
    }

  .mat-card-content>:first-child{
    padding-top: 20px;
     background-color: #f2f2f2; 
    border-radius: 5px 5px 5px 5px ;
    bottom:55px;
  box-shadow: 0px 0px 2px 1px #8ebece ;
  border-radius: 10px 10px 10px 10px;
     }
 .mat-form-field{
font-size: 17px;
 }
 mat-label{
   color:#087798; 
   font-size: 18px;
   border-style:none;
 }
 input.mat-input-element{
  color: #0b214e;
  font-size: 17px;
 }
 .mat-form-field
 {
     padding-left: 20px; 
     padding-top: 10px;
 }


 .tally-serach-form form{
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2; 
}
/* for form row css -padmaja */
form .row {
  background-color: #f2f2f2; 
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #172c59;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #0099cc;
  /* on focus */
}
.mat-calendar-table-header th{
  font-size: 15px!important;
    font-weight: 500!important;
    color: #10242b!important;
    resize: none!important;
    overflow: hidden!important;
}
.mat-calendar-body-label {
  font-size: 15px!important;
  color: #000000!important;
  height: 2px !important;
} 
 .mat-calendar-table-header-divider {
  font-size: 9px!important;
  position: relative!important;
  height: auto !important;
  resize: none!important;
    overflow: hidden!important;
} 


input[type=time]{
  color:#004c8b;
  background-color:#f2f2f2;
  padding:5px;
  font-size:14px;
  float:inline-end !important;
  margin-top: 10px;
  border-radius: 5px;
  border-color: 0.15px solid black;
}


.mat-raised-button[type="submit"]{
  /* padding: 2px 2px 2px 2px; */
  font-size: 16px;
   top: 0.05vh;
  /* bottom: 5vh; */
}

 .mat-dialog-container .mat-card.mat-focus-indicator{
  background-color:#ffffff !important; 
  align-items: center;
}
.mat-dialog-container .mat-card-header{
  width: fit-content;
  align-items: center;
} 
.mat-dialog-container .mat-card-content{
  top:25px;
   width: fit-content;
} 

@media screen  and (min-width: 712px) and (max-width:800px)  and (min-height: 600px)  {

  .mat-card-content>:first-child{
    background-color: #f5f5f5; 
   border-radius: 5px 5px 5px 5px ;
   bottom:45px;
  }
  
} 


@media screen and (min-width: 300px) and (max-width: 1500px) and (min-height: 600px) {
  .table2 {
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 14vw !important;
}
   table.mat-table {
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 14vw !important;
}
  .table{
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 14vw !important;
  }
  tr.mat-header-row th:first-child{
    border-collapse: collapse;
    border-radius: 10px 0px 0px 0px !important;
  }
  tr.mat-header-row th:last-child{
    border-collapse: collapse;
    border-radius: 0px 10px 0px 0px !important;
  }
  tr:last-child{
    border-collapse: collapse;
    border-radius: 10px 10px 10px 10px !important;
  }
  tr:first-child th:first-child { border-top-left-radius: 10px; }
tr:first-child th:last-child { border-top-right-radius: 10px; }

tr:last-child td:first-child { border-bottom-left-radius: 10px; }
tr:last-child td:last-child { border-bottom-right-radius: 10px; }
.mat-elevation-z8 tally-table{
  border-collapse: collapse;
  border-radius: 10px 10px 10px 10px !important;
}
tr{
  border-radius: 10px;
}
tr:first-child td { border-top-style: solid; }
tr td:first-child { border-left-style: solid; }
}


@media screen and (min-width: 768px) {
  .table2 {
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 3vw !important;
}
   table.mat-table {
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 3vw !important;
}
  .table{
    border-collapse: separate;
    border-radius: 10px 10px 10px 10px !important;
  border-spacing: 0;
  top: 3vw !important;
  }
}
.mat-dialog-container{
  font-size: 15px;
  /* scroll-behavior: vertical; */
}

.mat-dialog-container .mat-card-content .row{
  font-size: 17px;
  border: none !important;
  line-height: 1.75;
}
.mat-dialog-container .mat-card-content .form {
  top: 2px;
  /* width: -moz-fit-content; */
  /* width: fit-content; */
  padding: 10px;
  background-color: #f2f2f2; 
  box-shadow: 0px 0px 2px 1px #8ebece ;
   border-radius: 10px 10px 10px 10px; 
}

mat-dialog-container .mat-card {
  top: 5px;
  padding: 10px;
   background-color:  #ffffff !important; 
   width: auto;
}
.mat-dialog-container .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  margin-bottom: calc(-.5 * var(--bs-gutter-x));;
}
.mat-dialog-container .main-header-title{
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 10px 10px 10px 10px !important;
  background-image: -webkit-gradient(linear, right top, left bottom, 
     color-stop(0.3,  #172c59),
      color-stop(1, #069ed1));   
 /* background-color: #C2D6D6 ;  */
   -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
font-size: 17px;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn{
  font-size: 16px;
}
@media screen and (min-width: 760px) and (max-width: 1800px) and (min-height: 500px) {

.mat-card-header {
  height: 5vw !important;
}

    .mat-dialog-container mat-card{
      height: 40vw !important;
    }
   
}
@media screen and (width: 820px)  {

  .mat-card-header {
    height: 8vw !important;
  }
  
  }
.more_options_btn ul  {
  width: fit-content; /* Set the width to fill the available space */
  max-height: 200px; /* Set a maximum height to limit the content */ 
  overflow-y: auto; /* Add vertical scroll if the content exceeds the height */
  overflow-x: hidden;
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
  list-style: none; /* Remove the default bullet points */
}
.more_options_btn ul button {
  width: 100%;
  text-align: left;
  padding: 0 10px;
color: #087798;
} 
  .more_options_btn ul :hover {
    background-color: #00a9df !important;
  color: #ffffff !important;
border-radius: 0px!important;
}
.more_options_btn ul mat-label:hover {
  background-color: #00a9df;
color: #ffffff!important;
border-radius: 0px!important;
} 
.mat-datepicker input{
  font-size: 15px;
  background-color: #F5F5F5;
  float: right;
}
/* .mat-form-field:not(.mat-form-field-appearance-legacy) 
.mat-form-field-prefix .mat-icon-button 
.mat-datepicker-toggle-default-icon,  */
.mat-form-field:not(.mat-form-field-appearance-legacy) 
.mat-form-field-suffix .mat-icon-button
 .mat-datepicker-toggle-default-icon{
  float: right;
  font-size: 18px; 
  color: #087798;
}
.over-Flow{
 margin-bottom: 2vw;
}

@media screen and (max-width: 767px) {
  .mat-dialog-container{
    height:90px;
    overflow-y: scroll;
    width:fit-content !important;
    font-size: 17px!important;
    padding: 2px !important;
  
  }

  .mat-dialog-container mat-card mat-card-header{
    position: relative;
    height: 100%;
     /* top: 10vw;  */
    min-height: 10vw;
    margin-top: 5px !important;
    margin-bottom: 30px !important;
  }
  .mat-dialog-container mat-card-header .mat-card-title {
    margin: 5px !important;
    font-size: 2vw;
    font-weight: 500;
    font-family: 'Roboto';
    color: #f2f2f3;
}
.mat-dialog-container .mat-button{
  font-size: 14px;
  margin: 10px 10px 10px 0 !important;
}

.main-header-title .mat-card-header .mat-card-title {
  display: block !important;
  /* padding-top:-30px !important; */
} 
.mat-dialog-container .main-header-title{
margin-bottom: -30px !important;
}

.mat-dialog-container .mat-card-header .buttons{
  /* top: 3vw; */
  position: relative;
}
/* 
.mat-dialog-container .mat-header-cell, .mat-cell{
  font-size: 4vw;
}
 
  .mat-dialog-container mat-card>:first-child{
  align-items: center;
  margin-bottom: 4px !important;
     }
     .mat-dialog-container .mat-card-content>:first-child{
  padding-top: 48px;
  display: block;
   } */
 
  /* .mat-dialog-container .mat-card {
    width:fit-content;  
    
  } */
  /* .main-header-title .mat-card-title{
   font-size: 17px!important;
  } */
 
  .tally-table-pagination .mat-paginator-range-label {
    margin: 2px 5px 2px 2px !important; 
  }
  mat-dialog-container form span{
   display:block;  
   padding-bottom: 12px !important;
   font-weight: 500!important;
 }
 .mat-dialog-container form{
   width: 73vw !important; 
   margin-bottom: -9vw;
}
}

.mat-dialog-container .mat-card-header{
  padding: 5px;
    padding-bottom: 15px!important;
    /* border-bottom: 1px solid #c6c6c6; */
    padding-bottom: 1vw;
    height: 4vw !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.mat-dialog-container table{
 max-height: 500px;
  overflow-y: scroll;
  scroll-snap-type: var(--bs-gutter-y);
}

.mat-paginator-outer-container{
  margin-top: 3vh!important;
}
.mat-paginator-page-size-label, .mat-paginator-range-label{
  color: #0e0e0e;
    font-size: 15px;
}

mat-paginator .mat-select-value-text{
  color: #0e0e0e;
  font-size: 16px;
 
}
.mat-paginator-page-size-select {
  margin: 6px 4px 0 4px;
  width: 86px!important;
}

.mat-dialog-container form span{
   padding-right: 30px; 
  padding-bottom: 12px !important;
  font-weight: 500!important;
}

.mat-dialog-container{
  display: flex!important;
  flex-direction: column;
}
.mat-dialog-container form{
  /* width:100% !important; */
  /* width: 73vw !important; */
 padding-bottom: 25px !important; 
}
@media (min-width: 768px) {
  .mat-dialog-container .mat-card-content .row {
    width: 100% !important;
    
  }
   .mat-dialog-container .mat-card-content  {
    width: 100% !important;
      top:1.2vw !important;
  }
 
}

   .confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 10px;
}
.confirm-dialog-container .content-container{
    margin: 5px 5px 5px 5px;
    color: #000000;
    display: flex;
}
.confirm-dialog-container #close-icon{
    margin-left: auto;
    order: 2;
    font-weight: bolder;
}
.confirm-dialog-container #close-icon:hover{
cursor: pointer;
}
.confirm-dialog-container button{
float: right;
padding: 2px 2px 2px 2px;
border-radius: 5px 5px 5px 5px !important;
  margin: 5px;
}
.confirm-dialog-container #no-button{
/* height: 50px;
width: 50%; */
background-color: #00a9df;
color:white;
}

.confirm-dialog-container #yes-button{
    /* height: 50px;
    width: 50%; */
    background-color: #ff0000;
    color:white;
    padding-left: 5px;
}

.confirm-dialog-container span.content-span{
    padding:  35px 5px 35px 5px;
    text-align: right;
    font-size: 18px;
}

.mat-dialog-container .mat-card-content{
padding-bottom: 20px !important;
}


  
  